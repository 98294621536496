import React from "react"
import { Helmet } from "react-helmet";

import CTA from "../../components/subreddit/cta";
import SEO from "../../components/common/seo";


const SubSearch = ({ subreddit }) => {

  return (
    <div>
      <SEO title={subreddit ? `Search r/${subreddit.name}` : ''} />
      <CTA title={`Search conversations in r/${subreddit.name}`} />
    </div>
  );
}

export default SubSearch;
