import React, { useState, useEffect } from "react";
import {
  Link
} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { Popover } from 'react-tiny-popover'
import { Helmet } from "react-helmet";

import { redditService } from '../../services/reddit';
import { velocityToString, capitalizeFirst } from '../../utils/text';

import PreviewRedditSubmission from "../../components/reddit/submissionpreview";
import SubmissionTypeIcon from "../../components/reddit/submissiontypeicon";
import SEO from "../../components/common/seo";
import LoaderSkeleton from "../../components/common/loaderskeleton";


const SubTopics = ({ subreddit }) => {
  const [loadingPhrases, setLoadingPhrases] = useState(true);
  const [terminology, setTerminology] = useState({});
  const [loadingTopics, setLoadingTopics] = useState(true);
  const [topics, setTopics] = useState([]);
  const [loadingFlair, setLoadingFlair] = useState(true);
  const [flair, setFlair] = useState([]);
  const [loadingTypes, setLoadingTypes] = useState(true);
  const [types, setTypes] = useState([]);
  const [isHovering, setIsHovering] = useState(null); // tracks the phrase that's currently hovering

  useEffect(() => {
    fetchTopics();
    fetchFlair();
    fetchTypes();
  }, []);

  const fetchTopics = () => {
    setLoadingTopics(true)
    redditService.fetchSubredditTopics(subreddit.name, (response) => {
      setTopics(response.data)
      setLoadingTopics(false)
    }, (error, response) => {
      console.error(response)
      setLoadingTopics(false)
    });
  }

  // const fetchPhrases = () => {
  //   setLoadingPhrases(true)
  //   redditService.fetchSubredditKeywords(subreddit.name, (response) => {
  //     setTerminology(response.data)
  //     setLoadingPhrases(false)
  //   }, (error, response) => {
  //     console.error(response)
  //     setLoadingPhrases(false)
  //   });
  // }

  const fetchFlair = () => {
    setLoadingFlair(true)
    redditService.fetchSubredditFlair(subreddit.name, (response) => {
      setFlair(response.data)
      setLoadingFlair(false)
    }, (error, response) => {
      console.error(response)
      setLoadingFlair(false)
    });
  }

  const fetchTypes = () => {
    setLoadingTypes(true)
    redditService.fetchSubredditTypes(subreddit.name, (response) => {
      setTypes(response.data)
      setLoadingTypes(false)
    }, (error, response) => {
      console.error(response)
      setLoadingTypes(false)
    });
  }

  // to make width for topics
  var maxCount = 1;
  if (topics && topics.length){
    maxCount = Math.max(...topics.map(t => t.stats.recent_count))
  }

  if (loadingTopics || loadingFlair || loadingTypes){
    return (
      <div className="overflow-hidden rounded-lg bg-gray-800 text-white shadow p-4">
        <LoaderSkeleton />
      </div>
    )
  }


  return (
    <section id="subreddit-topics">
      <div className="rounded-lg bg-gray-800 text-white shadow p-4">
        <div className="" id="subreddit-topics">
          <dt className="flex items-center">
            <h2 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
              Submission Types in r/{subreddit.name}
            </h2>
            <ReactTooltip id={`types-info`} place="right" effect="solid" backgroundColor="white" textColor="black" />
            <span className="cursor-pointer ml-2" data-for='types-info' data-tip="Types of content that users post to this subreddit">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </span>
          </dt>
          
          <div className="flex flex-wrap">
            {loadingTypes ? '' : ''}
            {types.map(f => (
              <div key={f.label} className="px-2 py-1 flex space-x-1 items-center text-xs mr-1 mt-1 bg-gray-700 text-white rounded-sm">
                <span className="font-semibold opacity-75">{f.percent}%</span>
                <SubmissionTypeIcon type={f.label} />
                <span>{f.label}</span>
              </div>
            ))}
          </div>
          {types.length === 0 ? (
            <div className="opacity-50 mt-2">No recent submissions in past week</div>
          ) : ''}
        </div>

        {topics && topics.length ? (
          <div className="mt-4" id={`subreddit-topics-${subreddit.name}`}>
            <dt className="flex items-center">
              <h2 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
                Topics discussed in r/{subreddit.name}
              </h2>
              <ReactTooltip id={`terminology-info`} place="right" effect="solid" backgroundColor="white" textColor="black" />
              <span className="cursor-pointer ml-2" data-for='terminology-info' data-tip="Based on most recent discussions in this subreddit.">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </span>
            </dt>
            <dd className="mt-1">
              {topics
                .filter((t, i) => i <= 10)
                .map((k) => (
                <div key={k.topic} className="flex mb-2">
                  <div className="flex-1 whitespace-nowrap overflow-ellipsis flex-shrink-0 truncate">{capitalizeFirst(k.topic)}</div>
                  <div className="flex-1 bg-gray-700 rounded-sm relative flex-shrink-0">
                    {/*(<div className="absolute p-0.5 text-white text-xs">{k.velocity ? velocityToString(k.velocity) : ''}</div>*/}
                    <div className="bg-cyan-600 rounded-sm p-0.5 h-full" style={{width: (100 * k.stats.recent_count / maxCount) + '%'}}></div>
                  </div>

                  
                  <div className={`ml-2 flex items-center ${k.examples && k.examples.length ? '' : 'opacity-25 pointer-events-none'}`}>
                    <Popover
                      isOpen={isHovering === k.topic}
                      align="start"
                      // reposition={false}
                      parentElement={document.getElementById('subreddit-topics')}
                      // parentElement={document.getElementById(`subreddit-topics-${subreddit.name}`)}
                      content={() => (
                        <div className="z-40 absolute right-0 -bottom-6 pl-2 pb-2 pr-2"
                          onMouseEnter={() => setIsHovering(k.topic)}
                          onMouseLeave={() => setIsHovering(null)}
                        >
                          <div className="rounded-md bg-white text-gray-700 flex flex-col w-80 sm:w-96">
                            <div className="flex border-b-2 border-gray-200 p-2 sm:px-4 w-full items-center flex-shrink-0">
                              {/*<div className="opacity-50 mr-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                                </svg>
                              </div>*/}
                              <div className="flex-grow">
                                <h2 className="text-md font-medium m-0">Recent discussions about {capitalizeFirst(k.topic)}</h2>
                                {/*<div className="text-sm font-medium opacity-50">
                                  Containing about this topic
                                </div>*/}
                              </div>
                            </div>
                            <div className="max-h-60 overflow-y-scroll">
                              <ul className="divide-y divide-gray-200 py-2 px-2 sm:px-4">
                                {k.examples.map(item => (
                                  <React.Fragment key={item.id}>
                                    {true || item.type === 'reddit-submission' ? (
                                      <PreviewRedditSubmission item={item} keyword={k.topic} showMetadata={true} showRedirect={true} trackRedirect={false} />
                                    ) : ''}
                                  </React.Fragment>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                    >
                      <span className="cursor-pointer opacity-50 hover:opacity-75"
                        onMouseEnter={() => setIsHovering(k.topic)}
                          onMouseLeave={() => setIsHovering(null)}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                        </svg>
                      </span>
                    </Popover>
                  </div>
                  
                </div>
              ))} 
            </dd>
          </div>
        ) : ''}

        {flair && flair.length ? (
          <div className="mt-4" id="subreddit-topics">
            <dt className="flex items-center">
              <h2 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
                Flair Used in r/{subreddit.name}
              </h2>
              <ReactTooltip id={`flair-info`} place="right" effect="solid" backgroundColor="white" textColor="black" />
              <span className="cursor-pointer ml-2" data-for='flair-info' data-tip="Labels that users add to their posts on Reddit">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </span>
            </dt>
            <div className="flex flex-wrap">
              {flair.map(f => (
                <div key={f.label} className="px-2 py-1 flex space-x-1 items-center text-xs mr-1 mt-1 rounded-sm bg-gray-700 text-white"
                  style={{
                    // 'background': f.color || 'rgb(55, 65, 81)',
                    // 'color': f.color ? 'black' : 'white',
                  }}
                >
                  <span className="font-semibold opacity-75">{f.percent}%</span>
                  <span>{f.label}</span>
                </div>
              ))}
            </div>
          </div>  
        ) : ''} 
      </div>
    </section>
  );
}

export default SubTopics;
