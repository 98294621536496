import React from 'react';
import ReactDOM from 'react-dom';
import { hydrate, render } from "react-dom";

// import { Provider } from 'react-redux'
// import configureStore from './store';

import './styles/tailwind.output.css';
import './styles/index.css';
import './styles/loader.css';  // if we get too many of these, put into 1 file
// import "react-toggle/style.css" // react-toggle styles

import App from './App.jsx';
// import * as serviceWorker from './serviceWorker';


ReactDOM.render(
  <App />,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
