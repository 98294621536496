import React from "react"
import { Helmet } from "react-helmet";

import ReactTooltip from 'react-tooltip';
import SubredditTags from "../../components/reddit/subreddittags";
// import SEO from "../../components/common/seo"; // not needed unless we move this out of the root route

import { readableNumber } from "../../utils/text";

const SubInfo = ({ subreddit }) => {

  const stats = [
    { label: 'Members', value: readableNumber(subreddit.count_users) },
    { label: 'Created', value: subreddit.created },
    // { label: 'Growth', value: 'idk' },
  ]

  return (
    <section aria-labelledby="profile-overview-title">
      <div className="overflow-hidden rounded-lg text-white shadow bg-gray-800">
        <h2 className="sr-only" id="profile-overview-title">
          Subreddit Overview
        </h2>
        <div className="text-white p-6">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex sm:space-x-5">
              <div className="flex-shrink-0">
                {subreddit.icon ? (
                  <img src={subreddit.icon} className="mx-auto h-20 w-20 rounded-full" alt={subreddit.name} />
                ) : (
                  <div className="h-24 w-24 p-5 rounded-md">
                    <svg className="h-12 w-12 opacity-25" enableBackground="new 0 0 24 24" height="512" fill="currentColor" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                      <path d="m21.325 9.308c-.758 0-1.425.319-1.916.816-1.805-1.268-4.239-2.084-6.936-2.171l1.401-6.406 4.461 1.016c0 1.108.89 2.013 1.982 2.013 1.113 0 2.008-.929 2.008-2.038s-.889-2.038-2.007-2.038c-.779 0-1.451.477-1.786 1.129l-4.927-1.108c-.248-.067-.491.113-.557.365l-1.538 7.062c-2.676.113-5.084.928-6.895 2.197-.491-.518-1.184-.837-1.942-.837-2.812 0-3.733 3.829-1.158 5.138-.091.405-.132.837-.132 1.268 0 4.301 4.775 7.786 10.638 7.786 5.888 0 10.663-3.485 10.663-7.786 0-.431-.045-.883-.156-1.289 2.523-1.314 1.594-5.115-1.203-5.117zm-15.724 5.41c0-1.129.89-2.038 2.008-2.038 1.092 0 1.983.903 1.983 2.038 0 1.109-.89 2.013-1.983 2.013-1.113.005-2.008-.904-2.008-2.013zm10.839 4.798c-1.841 1.868-7.036 1.868-8.878 0-.203-.18-.203-.498 0-.703.177-.18.491-.18.668 0 1.406 1.463 6.07 1.488 7.537 0 .177-.18.491-.18.668 0 .207.206.207.524.005.703zm-.041-2.781c-1.092 0-1.982-.903-1.982-2.011 0-1.129.89-2.038 1.982-2.038 1.113 0 2.008.903 2.008 2.038-.005 1.103-.895 2.011-2.008 2.011z"/>
                    </svg>
                  </div>
                )}
              </div>
              <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                <h1 className="text-xl font-bold text-white sm:text-2xl">r/{subreddit.name}</h1>
                <p className="text-sm font-medium text-gray-200 mt-2">{subreddit.description}</p>


                {subreddit.tags && subreddit.tags.length ? (
                  <div className="mt-4">
                    <SubredditTags subreddit={subreddit} tags={subreddit.tags} toolTipPlace="right" tooltipPrefix={'modal-'}/>
                  </div>
                ) : ''}
                
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 divide-y divide-gray-700 border-t border-gray-700 sm:grid-cols-3 sm:divide-y-0 sm:divide-x">
          {stats.map((stat) => (
            <div key={stat.label} className="px-4 py-4 text-center text-sm font-medium">
              <div className="text-gray-200">{stat.value}</div>
              <div className="text-gray-400">{stat.label}</div>
            </div>
          ))}

          <div className="p-4 text-center">
            <a href={subreddit.link} target="_blank">
              <button className="items-center px-4 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-500">
                View on Reddit
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  )

  return (
    <div>
      <div className="flex">
        <div className="flex-1 mb-4">
          <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
            Created
          </h3>
          <div className="text-sm mt-1">
            {subreddit.created}
          </div>
        </div>

        <div className="flex-1 mb-4">
          <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
            Members
          </h3>
          <div className="text-sm mt-1">
            {readableNumber(subreddit.count_users)}
          </div>
        </div>

        {subreddit && subreddit.tags && subreddit.tags.length ? (
          <div className="flex-1 mb-4">
            <div className="flex items-center">
              <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
                At a glance
              </h3>
              <ReactTooltip id={`tag-info`} place="right" effect="solid" backgroundColor="white" textColor="black" />
              <span className="cursor-pointer ml-2" data-for='tag-info' data-tip="Things that stand out about this subreddit when comparing to all others tracked by GummySearch">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </span>
            </div>
            <div className="text-sm mt-1">
              <SubredditTags subreddit={subreddit} tags={subreddit.tags} toolTipPlace="right" tooltipPrefix={'modal-'}/>
            </div>
          </div>
        ) : ''}
      </div>

      

      {subreddit.description ? (
        <div className="mb-4">
          <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
            Description
          </h3>
          <div className="text-sm mt-1">
            {subreddit.description}
          </div>
        </div>
      ) : ''}
    </div>
  );
}

export default SubInfo;
