import React, { useState, useEffect } from "react";
import {
  Link
} from "react-router-dom";
import {
  TrendingUpIcon, InformationCircleIcon, QuestionMarkCircleIcon
} from '@heroicons/react/outline';
import { Helmet } from "react-helmet";

import ReactTooltip from 'react-tooltip';

import { redditService } from '../../services/reddit';
import { readableNumber } from '../../utils/text';

import SubredditTags from "../../components/reddit/subreddittags";
import SEO from "../../components/common/seo";
import LoaderSkeleton from "../../components/common/loaderskeleton";


const SubSimilar = ({ subreddit }) => {
  const [loading, setLoading] = useState(false);
  const [similar, setSimilar] = useState([]);

  useEffect(() => {
    fetchSubreddits();
  }, []);

  const fetchSubreddits = () => {
    if (subreddit && subreddit.related_subreddits && subreddit.related_subreddits.length){
      setLoading(true)
      redditService.fetchSubreddits(subreddit.related_subreddits, (response) => {
        setSimilar(response.data)
        setLoading(false)
      }, (error, response) => {
        console.error(response)
        setLoading(false)
      });
    }
  }

  if (loading){
    return (
      <div className="overflow-hidden rounded-lg bg-gray-800 text-white shadow p-4">
        <LoaderSkeleton />
      </div>
    )
  }

  return (
    <section aria-labelledby="announcements-title">
      <div className="overflow-hidden rounded-lg bg-gray-800 text-white shadow p-4">

        <div className="flex items-center  px-1 mb-2">
          <h2 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
            Similar to r/{subreddit.name}
          </h2>
          <ReactTooltip id={`similar-subreddits`} place="right" effect="solid" backgroundColor="white" textColor="black" />
          <span className="cursor-pointer ml-2" data-for='similar-subreddits' data-tip="Based on additional activity by members of this Subreddit.">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </span>
        </div>

        {similar.length === 0 ? (
          <div className="mx-1 text-sm opacity-50">
            No similar subreddits at this time...
          </div>
        ) : ''}
        <div className="-mx-2 space-y-2">
          {similar.map(s => (
            <a href={`${s.share_url}`} key={s.name}
              className="bg-gray-800 hover:bg-gray-700 rounded-md p-3 flex cursor-pointer"
            >
              
              <div className="h-12 w-12 mr-4 flex-shrink-0">
                {s.icon ? (
                  <img className="h-12 w-12 rounded-md" src={s.icon} alt={s.name} />
                ) : (
                  <div className="h-12 w-12 p-3 rounded-md bg-gray-600">
                    <svg className="h-6 w-6 opacity-25" enableBackground="new 0 0 24 24" height="512" fill="currentColor" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                      <path d="m21.325 9.308c-.758 0-1.425.319-1.916.816-1.805-1.268-4.239-2.084-6.936-2.171l1.401-6.406 4.461 1.016c0 1.108.89 2.013 1.982 2.013 1.113 0 2.008-.929 2.008-2.038s-.889-2.038-2.007-2.038c-.779 0-1.451.477-1.786 1.129l-4.927-1.108c-.248-.067-.491.113-.557.365l-1.538 7.062c-2.676.113-5.084.928-6.895 2.197-.491-.518-1.184-.837-1.942-.837-2.812 0-3.733 3.829-1.158 5.138-.091.405-.132.837-.132 1.268 0 4.301 4.775 7.786 10.638 7.786 5.888 0 10.663-3.485 10.663-7.786 0-.431-.045-.883-.156-1.289 2.523-1.314 1.594-5.115-1.203-5.117zm-15.724 5.41c0-1.129.89-2.038 2.008-2.038 1.092 0 1.983.903 1.983 2.038 0 1.109-.89 2.013-1.983 2.013-1.113.005-2.008-.904-2.008-2.013zm10.839 4.798c-1.841 1.868-7.036 1.868-8.878 0-.203-.18-.203-.498 0-.703.177-.18.491-.18.668 0 1.406 1.463 6.07 1.488 7.537 0 .177-.18.491-.18.668 0 .207.206.207.524.005.703zm-.041-2.781c-1.092 0-1.982-.903-1.982-2.011 0-1.129.89-2.038 1.982-2.038 1.113 0 2.008.903 2.008 2.038-.005 1.103-.895 2.011-2.008 2.011z"/>
                    </svg>
                  </div>
                )}
              </div>

              <div className="flex-col justify-between ">
                <div className="flex items-center">
                  
                  <div>
                    <h2 className="text-xl font-semibold text-white">
                      r/{ s.name }
                    </h2>

                    <div className="flex text-xs font-medium mt-0">
                      <div className="text-gray-400">
                        {readableNumber(s.count_users)} members
                      </div>

                      <ReactTooltip id={`subreddit-stats-${s.name}`} place="right" effect="solid" backgroundColor="white" textColor="black" />
                      {s.calculated && s.calculated.growth_members_weekly != null ? (
                        <div className={`ml-2 flex ${s.calculated.growth_members_weekly > 0 ? 'text-green-500' : s.calculated.growth_members_weekly === 0 ? 'text-gray-400' : 'text-red-400'}`}>
                          <TrendingUpIcon className="h-4 w-4 mr-1" />
                          <div>{s.calculated.growth_members_weekly.toFixed(2)}% / week</div>
                        </div>
                      ) : s.calculated && s.calculated.growth_members_daily != null? (
                        <div className="text-gray-500 ml-2 flex">
                          <TrendingUpIcon className="h-4 w-4 mr-1" />
                          <div>{s.calculated.growth_members_daily.toFixed(2)}% / day</div>
                          <InformationCircleIcon className="h-4 w-4 ml-1 text-gray-500" 
                            data-for={`subreddit-stats-${s.name}`} data-tip="No weekly growth data yet. Showing growth over the past day."
                          />
                        </div>
                      ) : ''}
                    </div>
                  </div>
                </div>

                {subreddit && s.tags && s.tags.length ? (
                  <div className="block mt-2">
                    <SubredditTags subreddit={subreddit} tags={s.tags} tagClassName="bg-gray-600 text-white text-xs py-1 px-2 text-xs mr-2 mb-1 rounded-sm"/>
                  </div>
                ) : ''}
                
                <div className="flex-1">
                  <p className={`mt-2 text-gray-300 text-sm overflow-ellipsis line-clamp-3`}
                  >
                    { s.description }
                  </p>
                </div>

                {/*{subreddit && s.related_subreddits && s.related_subreddits.length ? (
                  <div className="flex-1 mt-3">
                    <dt className="text-base font-normal text-gray-900">
                      Similar Subreddits
                    </dt>
                    <dd className="block mt-0 flex flex-wrap">
                      {s.related_subreddits.map(s => (
                        <div key={s} className="px-2 py-1 flex space-x-1 items-center text-xs mr-1 mt-1 bg-gray-200 text-gray-800 rounded-sm flex items-center">
                          <span>r/{s.replace('r/', '')}</span>
                          
                        </div>
                      ))}
                    </dd>
                  </div>
                ) : ''}*/}
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
}

export default SubSimilar;
