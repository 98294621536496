import React, { useState, useEffect } from "react";
// import {
//   Switch,
//   Route,
//   Link,
//   Redirect,
// } from "react-router-dom";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";

import ErrorBoundary from "../../components/common/errorboundary";
import LoaderFancy from '../../components/common/loaderfancy.jsx';
import ErrorBar from "../../components/common/errorBar";
import SEO from "../../components/common/seo";

import CollectionReport from "../../components/report/collectionReport";
import AnalysisPatternsReport from "../../components/report/analysisPatternsReport";
import AnalysisAskReport from '../../components/report/analysisAskReport';

import { sharedService } from '../../services/shared';
import { BASE_URL } from '../../utils/urls';



const SharedReport = ({ history, location, match }) => {
  const { hash } = match.params;
  const [report, setReport] = useState(null);
  const [reportResults, setReportResults] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  
  // effects
  useEffect(() => {
    fetchReport();
  }, []);

  // acions
  const fetchReport = () => {
    setLoading(true)
    sharedService.getReport(hash, (results) => {
      if (results && results.data){
        // go the report, set partial content for page
        setReport(results.data)

        // go get the results (loading is still true)
        fetchReportResults()
      }
    }, (response, error) => {
      setLoading(false)
      // setError(error);
      if (response.response && response.response.status === 404){
        setError("Sorry, what you're looking for no longer exists")
      } else if (response.response && response.response.status === 400) {
        setError(error)
      } else {
        setError("Server Error")
      }
    })
  }

  const fetchReportResults = () => {
    setLoading(true)
    sharedService.getReportResults(hash, (results) => {
      if (results && results.data){
        setReportResults(results.data);
        setLoading(false);
      }
    }, (response, error) => {
      setLoading(false)
      if (response.response && response.response.status === 404){
        setError("Sorry, what you're looking for no longer exists")
      } else if (response.response && response.response.status === 400) {
        setError(error)
      } else {
        setError("Server Error")
      }
    })
  }

  return (
    <div className="bg-gray-900 text-white">
      <ErrorBoundary>
        {loading ? (
          <React.Fragment>
            <LoaderFancy showDelay={1} />
            <SEO
              generateImage={true}
              path={`/report/${hash}/`} // at least use path for canonical while loading
            />
          </React.Fragment>
        ) : error ? (
          <div className="text-center h-screen w-full flex flex-col items-center justify-center p-8">
            <h2 className="font-bold text-4xl opacity-25 mb-4">Uh Oh...</h2>
            <h3 className="text-2xl opacity-75">{error}</h3>
            <div className="mt-2">
              <a href="https://gummysearch.com" target="_top" className="font-semibold hover:underline">
                Go back to safety
              </a>
            </div>
          </div>
        ) : report && reportResults ? (
          <div className="p-4 sm:p-8">
            {report.report_type === 'CO' ? (
              <CollectionReport report={report} results={reportResults} />
            ) : report.report_type === 'AI' && reportResults.analysis.params_analysis.reports_bulk[0] === "clusters" ? (
              <AnalysisPatternsReport report={report} results={reportResults} />
            ) : report.report_type === 'AI' && reportResults.analysis.params_analysis.reports_bulk[0] === "ask" ? (
              <AnalysisAskReport report={report} results={reportResults} />
            ) : 'Invalid report type'}
          </div>
        ) : ''}
      </ErrorBoundary>
    </div>
  );
};

export default SharedReport;

