import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import ReactTooltip from 'react-tooltip';

import {
  TrendingUpIcon, InformationCircleIcon, QuestionMarkCircleIcon
} from '@heroicons/react/outline'

import ErrorBoundary from "../../components/common/errorboundary";
import ErrorBar from "../../components/common/errorBar";
import SEO from "../../components/common/seo";

import { readableNumber, capitalizeFirst } from '../../utils/text';

import Cluster from "../../components/report/analysisPatternsCluster";

// import { collectionsService } from '../../services/collections';


const AnalysisPatternsReport = ({ report, results }) => {
  const analysis = results.analysis;
  const analysisResults = analysis.results;
  const analysisResultsContext = analysis.results_context;
  const patterns = analysisResults.all.clusters.clusters;
  const [error, setError] = useState(null);
  // const viewOptions = ['row', 'preview', 'full'];
  const [viewOption, setViewOption] = useState('preview'); // not configurable now but could be

  // for one cluster, get one of the results that we did the analysis on
  const getClusterResults = (c) => {
    return (analysisResultsContext || []).filter(r => {
      return c.items.map(i => i.item.id).includes(r.id)
    });
  }

  // get the link to redirect users from
  var link = report.affiliate_link || 'https://gummysearch.com';
  if (link.includes('?')){
    link += '&utm_source=share.gummysearch.com'
  } else {
    link += '?utm_source=share.gummysearch.com'
  }

  var title = 'Patterns Analysis';
  if (analysis.params_results.suggestion){
    title += ` - ${capitalizeFirst(analysis.params_results.suggestion)}`
  }
  var subtitle = `${analysis.params_results.limit} ${analysis.params_results.type}`
  if (analysis.params_results.subreddits){
    subtitle += ` in ${analysis.params_results.subreddits.split(',').map(s => `r/${s}`).join(', ')}`
  }

  return (
    <ErrorBoundary>
      <SEO
        title={`${title} | GummySearch`}
        // description={`A collection of ${results ? results.title : ''} subreddits: ${sortedItems.map(s => `r/` + s.item.name).join(', ')}`}
        generateImage={true} path={`/report/${report.hash}/`}
      />

      <div className="mb-6 pb-5 border-b border-solid border-gray-800 flex items-center flex-wrap">
        <div>
          <div className="flex-1 min-w-0">
            <h1 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate">{title}</h1>
          </div>

          <div className="mt-2 flex flex-wrap space-x-2 sm:space-x-4 text-xs sm:text-sm">
            <div className="flex items-center text-gray-300">
              {subtitle}
            </div>
            
          </div>
        </div>

        <div className="sm:ml-auto mt-4 md:mt-0 text-center">
          <div className="text-gray-400 font-semibold">GummySearch Audience Research</div>
          <div className="sm:float-right mt-2 sm:mt-0">
            <a href={link}
              type="button" target="_blank"
              className="items-center px-4 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-500"
            >
              Research any niche on Reddit (for free)
            </a>
          </div>
        </div>
      </div>


      <div className="mt-4 max-w-3xl mx-auto">
        <div className="flex items-center mb-2">
          <div className="text-lg font-medium flex items-center text-white">
            <span>Patterns</span>

            <p className="ml-2 text-gray-500 truncate">
              {patterns.length}
            </p>
          </div>

          <div className="ml-auto"></div>
        </div>
          
        <div className="space-y-2">
          {patterns.map((c, i) => (
            <Cluster key={c.name} c={c} isFirst={i === 0} results={getClusterResults(c)}
              // getPopoverParentElement={() => document.getElementById(`clusters-analysis`)}
              viewOption={viewOption} 
            />
          ))}
        </div>
        
      </div>

      {error ? (
        <ErrorBar error={error} setError={setError} />
      ) : ''}
    </ErrorBoundary>
  );
};

export default AnalysisPatternsReport;

