import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";

import {
  TrendingUpIcon, InformationCircleIcon, QuestionMarkCircleIcon
} from '@heroicons/react/outline';
import ReactTooltip from 'react-tooltip';


import ErrorBoundary from "../common/errorboundary";
import SubredditTags from "../reddit/subreddittags";

import SubredditTopics from "../subreddit/subredditTopics";
import SubredditSubmissions from "../subreddit/subredditSubmissions";

import { readableNumber } from '../../utils/text';
// import { getParamsFromUrl, toQueryString } from '../../utils/urls';
import { trackEvent } from '../../utils/tracking';

// import { searchesService } from '../../services/searches';
import { redditService } from '../../services/reddit';
// import { collectionsService } from '../../services/collections';


// a subreddit as part of a shared report
const CollectionSubredditCard = ({
  subreddit, collectionItem,
}) => {
  // state
  const views = ['Basic Info', 'Topics', 'Popular Posts', 'More'];
  // const views = ['details'];
  const [view, setView] = useState(views[0]);
  const [showDescription, setShowDescription] = useState(false);

  return (
    <div key={collectionItem.id} className="rounded-md shadow-lg bg-gray-800 text-white flex p-4 flex-col">
      <ErrorBoundary>
        <div className="flex items-center">
          {subreddit && subreddit.icon ? (
            <img className="h-12 w-12 mr-4 rounded-md" src={subreddit.icon} alt={subreddit.name} />
          ) : (
            <div className="h-12 w-12 mr-4 rounded-md bg-gray-700">
              <svg className="h-6 w-6 m-3 opacity-25" enableBackground="new 0 0 24 24" height="512" fill="currentColor" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                <path d="m21.325 9.308c-.758 0-1.425.319-1.916.816-1.805-1.268-4.239-2.084-6.936-2.171l1.401-6.406 4.461 1.016c0 1.108.89 2.013 1.982 2.013 1.113 0 2.008-.929 2.008-2.038s-.889-2.038-2.007-2.038c-.779 0-1.451.477-1.786 1.129l-4.927-1.108c-.248-.067-.491.113-.557.365l-1.538 7.062c-2.676.113-5.084.928-6.895 2.197-.491-.518-1.184-.837-1.942-.837-2.812 0-3.733 3.829-1.158 5.138-.091.405-.132.837-.132 1.268 0 4.301 4.775 7.786 10.638 7.786 5.888 0 10.663-3.485 10.663-7.786 0-.431-.045-.883-.156-1.289 2.523-1.314 1.594-5.115-1.203-5.117zm-15.724 5.41c0-1.129.89-2.038 2.008-2.038 1.092 0 1.983.903 1.983 2.038 0 1.109-.89 2.013-1.983 2.013-1.113.005-2.008-.904-2.008-2.013zm10.839 4.798c-1.841 1.868-7.036 1.868-8.878 0-.203-.18-.203-.498 0-.703.177-.18.491-.18.668 0 1.406 1.463 6.07 1.488 7.537 0 .177-.18.491-.18.668 0 .207.206.207.524.005.703zm-.041-2.781c-1.092 0-1.982-.903-1.982-2.011 0-1.129.89-2.038 1.982-2.038 1.113 0 2.008.903 2.008 2.038-.005 1.103-.895 2.011-2.008 2.011z"/>
              </svg>
            </div>
          )}

          {subreddit ? (
            <div className="text-xl font-semibold">
              <h2>
                <a href={`${subreddit.share_url}`} target="_blank" className="cursor-pointer hover:underline"
                  onClick={() => {
                    // trackEvent("ExternalRedirect", {
                    //   "platform": "Reddit",
                    //   "type": "Subreddit",
                    //   // "keywords": keywords,
                    //   "platform_id": subreddit.name,
                    // });
                    // searchesService.saveRedirect("RSR", subreddit.name, subreddit.name);
                  }}
                >
                  r/{ subreddit.name }
                </a>
              </h2>
              <div className="text-xs font-medium mt-1 flex items-center text-gray-400">
                <div>{readableNumber(subreddit.count_users)} members</div>
                <span className="opacity-50 mx-1" aria-hidden="true">&middot;</span>

                <ReactTooltip id={`subreddit-stats-${subreddit.name}`} place="right" effect="solid" backgroundColor="white" textColor="black" />
                {subreddit.calculated && subreddit.calculated.growth_members_weekly != null ? (
                  <div className={`ml-2 flex ${subreddit.calculated.growth_members_weekly > 0 ? 'text-green-400' : subreddit.calculated.growth_members_weekly === 0 ? 'text-gray-400' : 'text-red-400'}`}>
                    <TrendingUpIcon className="h-4 w-4 mr-1" />
                    <div>{subreddit.calculated.growth_members_weekly.toFixed(2)}% / week</div>
                  </div>
                ) : subreddit.calculated && subreddit.calculated.growth_members_daily != null? (
                  <div className="text-gray-400 ml-2 flex">
                    <TrendingUpIcon className="h-4 w-4 mr-1" />
                    <div>{subreddit.calculated.growth_members_daily.toFixed(2)}% / day</div>
                    <InformationCircleIcon className="h-4 w-4 ml-1 text-gray-500" 
                      data-for={`subreddit-stats-${subreddit.name}`} data-tip="No weekly growth data yet. Showing growth over the past day."
                    />
                  </div>
                ) : (
                  <div className="text-gray-400 ml-2 flex">
                    <TrendingUpIcon className="h-4 w-4 mr-1" />
                    <QuestionMarkCircleIcon className="h-4 w-4 ml-1 text-gray-500"
                      data-for={`subreddit-stats-${subreddit.name}`} data-tip="No growth data yet. We're working on it."
                    />
                  </div>
                )}
              </div>

              
            </div>
          ) : (
            <h2 className="text-xl font-semibold">r/{subreddit.name}
              <div className="text-xs font-medium mt-1 opacity-50">
                Fetching Details...
              </div>
            </h2>
          )}
        </div>

        <div className="mt-3 mb-3 pb-3 flex items-center border-b border-gray-700 flex-wrap">
          {views.map(v => (
            <div key={v} className={`p-1 sm:p-2 mr-2 font-medium text-xs sm:text-sm rounded-md ${v === view  ? 'bg-gray-600 text-white' : 'bg-gray-800 cursor-pointer hover:bg-gray-700'}`}
              onClick={() => setView(v)}
            >
              {v}
            </div>
          ))}
        </div>

        {/*<nav className="flex mb-4">
          {views.map((v) => (
            <div key={v} onClick={() => setView(v)}
              className={`whitespace-nowrap flex-1 py-4 border-b-2 cursor-pointer text-center text-sm ${v === view ? 'border-cyan-500 text-cyan-500' : 'border-transparent text-gray-300 hover:text-gray-200 hover:border-gray-200'}`}
            >
              {v.split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')}
            </div>
          ))}
        </nav>*/}


        <div className="h-44 overflow-y-auto">
          {view === 'Basic Info' ? (
            <React.Fragment>

              <div className="grid grid-cols-3">
                
                <div>
                  <dt className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
                    Created
                  </dt>
                  <dd className="mt-1">
                    <p className={`text-sm`}>
                      { subreddit && subreddit.created }
                    </p>
                  </dd>
                </div>

                {subreddit && subreddit.tags && subreddit.tags.length ? (
                  <div className="col-span-2">
                    <ReactTooltip id={`subreddit-comparison-info-${subreddit.name}`} place="right" effect="solid" backgroundColor="white" textColor="black" />
                
                    <dt className="text-xs font-semibold text-gray-400 uppercase tracking-wide flex items-center">
                      <div>In Comparison</div>

                      <span className="cursor-pointer ml-2" data-for={`subreddit-comparison-info-${subreddit.name}`} data-tip="Compared to all other subreddits tracked by GummySearch users">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </span>
                    </dt>
                    <dd className="mt-1">
                      <div className={`text-sm`}>
                        <SubredditTags subreddit={subreddit} tags={subreddit.tags} style="link" />
                      </div>
                    </dd>
                  </div>
                ) : ''}
              </div>

              {subreddit && subreddit.description ? (
                <React.Fragment>
                  <dt className="mt-3 text-xs font-semibold text-gray-400 uppercase tracking-wide">
                    Description
                  </dt>
                  <dd className="mt-1">
                    <p className={`text-sm opacity-100 overflow-ellipsis ${showDescription ? '' : 'cursor-pointer line-clamp-3'}`}
                      onClick={() => setShowDescription(true)}
                      // style={{minHeight: '70px'}}
                    >
                      { subreddit && subreddit.description }
                    </p>
                  </dd>
                </React.Fragment>
              ) : ''}

            </React.Fragment>
          ) : view === 'Topics' ? (
            <SubredditTopics subreddit={subreddit} hideIfBlankSection={true} showTerminologyExamples={true} dontTrackRedirect={true}/>
          ) : view === 'Popular Posts' ? (
            <SubredditSubmissions subreddit={subreddit} showSearch={false} showRedirect={true} trackRedirect={false} />
          ) : view === 'More' ? (
            <div>
              <div>See the public report for r/{subreddit.name} to see comparison charts, similar subreddits, frequent topics, and popular posts</div>
              <div className="mt-4">
                <a href={`${subreddit.share_url}`} target="_blank"
                  className="w-full bg-gray-700 text-white p-3 px-4 rounded-sm opacity-90 hover:opacity-100 font-semibold text-sm"
                >
                  View Public Report
                </a>
              </div>
            </div>
          ) : ''}
        </div>
      </ErrorBoundary>
    </div>
  )
}

export default CollectionSubredditCard;

