import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { ToastContainer, Slide, toast } from 'react-toastify';

import PropsRoute from './components/common/propsroute.jsx';


import Home from './pages/index.jsx'
import NotFound404 from './pages/404.jsx';
import SharedReport from './pages/report/report.jsx';
import PublicSubreddit from './pages/subreddit/index.jsx';

import 'react-toastify/dist/ReactToastify.css';


const App = (props) => {

  return (
    <Router>
      <ToastContainer hideProgressBar transition={Slide}/>
      
      
      <Switch>
        <PropsRoute path="/report/:hash/" component={SharedReport} />
        <PropsRoute path="/subreddit/r-:slug/" component={PublicSubreddit} />

        {/* redirects & catchall */}
        <PropsRoute path="/" exact={true} component={Home} />
        <PropsRoute path="/404/" component={NotFound404}/>
        <Redirect to="/404/" /> {/* catchall */}
      </Switch>
    </Router>
  );
}

export default App;

