import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import ReactTooltip from 'react-tooltip';
import Markdown from 'markdown-to-jsx'

import {
  TrendingUpIcon, InformationCircleIcon, QuestionMarkCircleIcon
} from '@heroicons/react/outline'

import ErrorBoundary from "../../components/common/errorboundary";
import ErrorBar from "../../components/common/errorBar";
import SEO from "../../components/common/seo";

import { readableNumber, capitalizeFirst } from '../../utils/text';
import { timeStampToEasyTime } from '../../utils/time';

import SubmissionTypeIcon from "../reddit/submissiontypeicon";
import Cluster from "../../components/report/analysisPatternsCluster";

// import { collectionsService } from '../../services/collections';


const AnalysisAskReport = ({ report, results }) => {
  const analysis = results.analysis;
  const analysisResults = analysis.results;
  const analysisResultsContext = analysis.results_context;
  const askAnalysis = analysisResults.all.ask;
  const citations = askAnalysis.citations;
  const [expandedCitations, setExpandedCitations] = useState([]);
  const [error, setError] = useState(null);
  // const viewOptions = ['row', 'preview', 'full'];
  // const [viewOption, setViewOption] = useState('preview'); // not configurable now but could be

  // // for one cluster, get one of the results that we did the analysis on
  // const getClusterResults = (c) => {
  //   return (analysisResultsContext || []).filter(r => {
  //     return c.items.map(i => i.item.id).includes(r.id)
  //   });
  // }

  // get the link to redirect users from
  var link = report.affiliate_link || 'https://gummysearch.com';
  if (link.includes('?')){
    link += '&utm_source=share.gummysearch.com'
  } else {
    link += '?utm_source=share.gummysearch.com'
  }

  var title = askAnalysis.question;
  // if (analysis.params_results.suggestion){
  //   title += ` - ${capitalizeFirst(analysis.params_results.suggestion)}`
  // }
  var subtitle = `${analysis.params_results.limit} ${analysis.params_results.type}`
  if (analysis.params_results.subreddits){
    subtitle += ` in ${analysis.params_results.subreddits.split(',').map(s => `r/${s}`).join(', ')}`
  }

  const InlineCitation = ({type, id}) => {
    // console.log("citation", post_id, comment_id)
    var citation = null;
    if (type === "comment"){
      citation = citations.filter(c => c.type === "comment" && c.id === id)[0]
    }
    if (type === "post"){
      citation = citations.filter(c => c.type === "post" && c.id === id)[0]
    }

    if (citation){
      return <span className="text-gray-500 font-bold cursor-pointer hover:text-gray-400"
        onClick={(e) => {
          e.preventDefault()
          if (!expandedCitations.includes(id)) {
            setExpandedCitations([...expandedCitations, id])
          } else {
            setExpandedCitations(expandedCitations.filter(i => i !== id))
          }
        }}>
        [{citation.index}]
      </span>
    }
    return <span className="text-red-500 font-bold">[?]</span>
  }

  const renderPostContents = (c) => {
    return (
      <div>
        {['link', 'crosspost'].includes(c.post.type) ? (
          <div className="py-2 xl:pt-4 xl:pb-0">
            <a href={c.post.url} target="_blank" rel="noreferrer"
              onClick={() => {
                // trackEvent("ExternalRedirect", {
                //   "platform": "Reddit",
                //   "type": "SubmissionSource",
                //   "platform_id": submission.id,
                // });
                // searchesService.saveRedirect("RSU", submission.id, submission.title);
              }}
            >
              {c.post.thumbnail && c.post.thumbnail !== 'default' ? (
                <div className="relative rounded-md">
                  <img className="w-full h-full rounded-md" src={c.post.thumbnail} width="100%" alt={c.post.title}/>
                  <div className="absolute top-0 right-0 w-full h-full bg-black opacity-50 rounded-md" aria-hidden="true"></div>
                  <div className="absolute top-2 left-2 right-2 overflow-hidden text-sm flex items-center p-2 bg-white text-gray-900 rounded-md opacity-50">
                    <svg className="flex-shrink-0 h-4 w-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                    </svg>
                    <div className="whitespace-nowrap overflow-ellipsis overflow-hidden">{c.post.url}</div>
                  </div>
                </div>
              ) : (
                <div className="relative rounded-md p-4 bg-gray-700 break-all text-white text-sm border border-gray-600 shadow-sm">
                  <svg className="inline-block h-4 w-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                  </svg>
                  <span>{c.post.url}</span>
                </div>
              )}
            </a>
          </div>
        ) : ''}

        {c.post.type === 'image' ? (
          <div className="py-4 xl:pt-4 xl:pb-0 flex flex-wrap">
            {c.post.media.map(url => (
              <div className="relative rounded-md w-96 mb-4 bg-gray-100" key={url} style={{minHeight: '100px'}}>
                <img className="w-full rounded-md" src={url} width="100%" alt={c.post.title}/>
              </div>
            ))}
          </div>
        ) : ''}
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <SEO
        title={`${title} | GummySearch`}
        // description={`A collection of ${results ? results.title : ''} subreddits: ${sortedItems.map(s => `r/` + s.item.name).join(', ')}`}
        generateImage={true} path={`/report/${report.hash}/`}
      />

      <div className="mb-6 pb-5 border-b border-solid border-gray-800 flex items-center flex-wrap">
        <div>
          <div className="flex-1 min-w-0">
            <h1 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate">{title}</h1>
          </div>

          <div className="mt-2 flex flex-wrap space-x-2 sm:space-x-4 text-xs sm:text-sm">
            <div className="flex items-center text-gray-300">
              {subtitle}
            </div>
          </div>
        </div>

        <div className="sm:ml-auto mt-4 md:mt-0 text-center">
          <div className="text-gray-400 font-semibold">GummySearch Audience Research</div>
          <div className="sm:float-right mt-2 sm:mt-0">
            <a href={link}
              type="button" target="_blank"
              className="items-center px-4 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-500"
            >
              Research any niche on Reddit (for free)
            </a>
          </div>
        </div>
      </div>


      <div className="mt-4 max-w-3xl md:max-w-full md:flex md:space-x-8">
        <div className="md:flex-1 md:flex-shrink-0 md:w-1/2">

          <div className="mt-4">
            <div className="p-6 bg-gray-800 rounded-lg text-white">

              <div className="flex border-b border-gray-600 pb-4 mb-4 w-full">
                <h2 className="text-xl font-medium flex items-center flex-wrap">
                  <div className="text-gray-100 font-semibold">{askAnalysis.question}</div>
                </h2>
              </div>

              <div className="text-lg text-gray-200">
                <Markdown options={{
                  wrapper: 'article',
                  overrides: {
                     InlineCitation: {
                      component: InlineCitation,
                    },
                    p: {
                      props: {
                        className: 'mb-4',
                      },
                    },
                    ol: {
                      props: {
                        className: 'list-decimal pl-6',
                      },
                    },
                  },
                }}>
                  {askAnalysis.answer}
                </Markdown>
              </div>
            </div>
          </div>
        </div>

        <div className="md:flex-1 md:flex-shrink-0 mt-4 md:w-1/2">
          <div className="space-y-8">
            {citations.map(c => (
              <div key={`${c.type}-${c.id}-${c.index}`} className="text-gray-400 p-4 rounded-md bg-gray-800 cusror-pointer"
                className={`text-base p-4 rounded-md bg-gray-800 ${expandedCitations.includes(c.id) ? '' : 'cursor-pointer hover:bg-gray-700'}`}
                onClick={() => {
                  if (!expandedCitations.includes(c.id)){
                    setExpandedCitations([...expandedCitations, c.id])
                    // scrollIntoView(c);
                  }
                }}
              >
                <div className="flex items-top">
                  <div className="mr-3 flex items-center cursor-pointer opacity-50 hover:opacity-75 cursor-pointer font-bold" onClick={(e) => {
                    e.preventDefault()
                    if (expandedCitations.includes(c.id)) {
                      setExpandedCitations(expandedCitations.filter(id => id !== c.id))
                    }
                  }}>
                    <div className="mr-1">{c.index}</div>
                    {!expandedCitations.includes(c.id) ? (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                      </svg>
                    )}
                  </div>
                  <div>
                    <span className="font-semibold">{c.post.title}</span> <span className="text-gray-400"> in {c.post.subreddit_name}</span>
                  </div>
                </div>
                <div className="pl-11 mt-2">
                  {c.type === "comment" ? (
                    <div className="">
                      <div className={`text-base text-gray-400 ${expandedCitations.includes(c.id) ? 'tripleLineClip' : 'singleLineClip'} mb-1`}>{c.post.body}</div>

                      {expandedCitations.includes(c.id) ? (
                        <React.Fragment>{renderPostContents(c)}</React.Fragment>
                      ) : ''}

                      <div className={`text-base text-white ${expandedCitations.includes(c.id) ? '' : 'doubleLineClip'}`}>{c.comment.body}</div>

                      {expandedCitations.includes(c.id) ? (
                        <div className="flex mt-4 items-center text-gray-400">
                          <svg className="mr-1 flex-shrink-0 h-4 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                          </svg>
                          <div className="text-sm text-gray-400">Comment by {c.comment.author} on {timeStampToEasyTime(c.comment.timestamp_utc)}</div>
                          <div className="ml-auto">
                            <a href={`https://reddit.com${c.comment.link}`} target={"_blank"} rel="noreferrer"
                              // onClick={() => {
                              //   trackEvent("ExternalRedirect", {
                              //     "platform": "Reddit",
                              //     "type": "Submission",
                              //     "platform_id": submission.id,
                              //   });
                              //   searchesService.saveRedirect("RSU", submission.id, submission.title);
                              //   if (onRedirect){
                              //     onRedirect(submission.id)
                              //   }
                              // }}
                            >
                              <button  type="button" className="bg-cyan-500 inline-flex px-4 py-2 shadow-sm text-sm text-white font-medium rounded-md opacity-100 hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
                                <svg className="-ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFF" viewBox="0 0 24 24">
                                  <path fillRule="evenodd" d="M14.238 15.348c.085.084.085.221 0 .306-.465.462-1.194.687-2.231.687l-.008-.002-.008.002c-1.036 0-1.766-.225-2.231-.688-.085-.084-.085-.221 0-.305.084-.084.222-.084.307 0 .379.377 1.008.561 1.924.561l.008.002.008-.002c.915 0 1.544-.184 1.924-.561.085-.084.223-.084.307 0zm-3.44-2.418c0-.507-.414-.919-.922-.919-.509 0-.923.412-.923.919 0 .506.414.918.923.918.508.001.922-.411.922-.918zm13.202-.93c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-5-.129c0-.851-.695-1.543-1.55-1.543-.417 0-.795.167-1.074.435-1.056-.695-2.485-1.137-4.066-1.194l.865-2.724 2.343.549-.003.034c0 .696.569 1.262 1.268 1.262.699 0 1.267-.566 1.267-1.262s-.568-1.262-1.267-1.262c-.537 0-.994.335-1.179.804l-2.525-.592c-.11-.027-.223.037-.257.145l-.965 3.038c-1.656.02-3.155.466-4.258 1.181-.277-.255-.644-.415-1.05-.415-.854.001-1.549.693-1.549 1.544 0 .566.311 1.056.768 1.325-.03.164-.05.331-.05.5 0 2.281 2.805 4.137 6.253 4.137s6.253-1.856 6.253-4.137c0-.16-.017-.317-.044-.472.486-.261.82-.766.82-1.353zm-4.872.141c-.509 0-.922.412-.922.919 0 .506.414.918.922.918s.922-.412.922-.918c0-.507-.413-.919-.922-.919z"/>
                                </svg>
                                <span>View</span>
                              </button>
                            </a>
                          </div>
                        </div>
                      ) : ''}
                    </div>
                  ) : c.type === "post" ? (
                    <div className="w-5/6 relative">
                      {expandedCitations.includes(c.id) ? (
                        <React.Fragment>{renderPostContents(c)}</React.Fragment>
                      ) : ''}

                      <div className={`text-base text-white pre-wrap ${expandedCitations.includes(c.id) ? '' : 'tripleLineClip text-gray-400'}`}>{c.post.body}</div>
                      {expandedCitations.includes(c.id) ? (
                         <ul className="mt-4 space-y-1 w-full">
                          {c.post.replies.map(comment => (
                            <li key={comment.id} className="text-base flex items-top text-gray-400  w-full">
                              <div className="w-14 flex-shrink-0 text-center flex items-center">
                                <SubmissionTypeIcon type="arrow-up" className="self-center flex-shrink-0 h-4 w-5" />
                                <span className="mr-4">{comment.score}</span>
                              </div>
                              <span className="singleLineClip text-gray-200 text-base w-96">{comment.body}</span>
                            </li>
                          ))}
                        </ul>
                      ) : ''}

                      {expandedCitations.includes(c.id) ? (
                        <div className="flex mt-4 items-center text-gray-400">
                          <SubmissionTypeIcon type={c.post.type} className="mr-1 flex-shrink-0 h-4 w-5"/>
                          <div className="text-sm text-gray-400">Post by {c.post.author} on {timeStampToEasyTime(c.post.timestamp_utc)}</div>
                          <div className="ml-auto">
                            <a href={`https://reddit.com${c.post.link}`} target={"_blank"} rel="noreferrer"
                              // onClick={() => {
                              //   trackEvent("ExternalRedirect", {
                              //     "platform": "Reddit",
                              //     "type": "Submission",
                              //     "platform_id": submission.id,
                              //   });
                              //   searchesService.saveRedirect("RSU", submission.id, submission.title);
                              //   if (onRedirect){
                              //     onRedirect(submission.id)
                              //   }
                              // }}
                            >
                              <button  type="button" className="bg-cyan-500 inline-flex px-4 py-2 shadow-sm text-sm text-white font-medium rounded-md opacity-100 hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
                                <svg className="-ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFF" viewBox="0 0 24 24">
                                  <path fillRule="evenodd" d="M14.238 15.348c.085.084.085.221 0 .306-.465.462-1.194.687-2.231.687l-.008-.002-.008.002c-1.036 0-1.766-.225-2.231-.688-.085-.084-.085-.221 0-.305.084-.084.222-.084.307 0 .379.377 1.008.561 1.924.561l.008.002.008-.002c.915 0 1.544-.184 1.924-.561.085-.084.223-.084.307 0zm-3.44-2.418c0-.507-.414-.919-.922-.919-.509 0-.923.412-.923.919 0 .506.414.918.923.918.508.001.922-.411.922-.918zm13.202-.93c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-5-.129c0-.851-.695-1.543-1.55-1.543-.417 0-.795.167-1.074.435-1.056-.695-2.485-1.137-4.066-1.194l.865-2.724 2.343.549-.003.034c0 .696.569 1.262 1.268 1.262.699 0 1.267-.566 1.267-1.262s-.568-1.262-1.267-1.262c-.537 0-.994.335-1.179.804l-2.525-.592c-.11-.027-.223.037-.257.145l-.965 3.038c-1.656.02-3.155.466-4.258 1.181-.277-.255-.644-.415-1.05-.415-.854.001-1.549.693-1.549 1.544 0 .566.311 1.056.768 1.325-.03.164-.05.331-.05.5 0 2.281 2.805 4.137 6.253 4.137s6.253-1.856 6.253-4.137c0-.16-.017-.317-.044-.472.486-.261.82-.766.82-1.353zm-4.872.141c-.509 0-.922.412-.922.919 0 .506.414.918.922.918s.922-.412.922-.918c0-.507-.413-.919-.922-.919z"/>
                                </svg>
                                <span>View</span>
                              </button>
                            </a>
                          </div>
                        </div>
                      ) : ''}
                    </div>
                  ) : 'Invalid Type'}
                </div>
              </div>
            ))}
          </div>
        </div>
        
      </div>

      {error ? (
        <ErrorBar error={error} setError={setError} />
      ) : ''}
    </ErrorBoundary>
  );
};

export default AnalysisAskReport;

