
import axios from 'axios';
// import { isExpired, decodeToken } from "react-jwt";


const BASE_URL = process.env.NODE_ENV === 'production' ? `https://${process.env.REACT_APP_RENDER_API_SERVICE}.onrender.com` : 'http://localhost:8000';


const getRequest = (url, successCallback, errorCallback) => {
  return axios.get(BASE_URL + url)
    .then(response => {
        if (successCallback){
            return successCallback(response)
        }
    })
    .catch((error) => {
      if (errorCallback){
        const message = error.response && error.response.status === 500 ? 'Server Error' : (error.response && error.response.data);
        return errorCallback(error, message)
      }
    });
}

const postRequest = (url, data, successCallback, errorCallback) => {
  return axios.post(BASE_URL + url, data)
    .then((response) => {
        if (successCallback){
            return successCallback(response)
        }
    })
    .catch((error) => {
      if (errorCallback){
        const message = error.response && error.response.status === 500 ? 'Server Error' : (error.response && error.response.data);
        return errorCallback(error, message)
      }
    });
}

const patchRequest = (url, data, successCallback, errorCallback) => {
  return axios.patch(BASE_URL + url, data)
    .then((response) => {
        if (successCallback){
            return successCallback(response)
        }
    })
    .catch((error) => {
      if (errorCallback){
        const message = error.response && error.response.status === 500 ? 'Server Error' : (error.response && error.response.data);
        return errorCallback(error, message)
      }
    });
}

const deleteRequest = (url, successCallback, errorCallback) => {
  return axios.delete(BASE_URL + url)
    .then((response) => {
        if (successCallback){
            return successCallback(response)
        }
    })
    .catch((error) => {
      if (errorCallback){
        const message = error.response && error.response.status === 500 ? 'Server Error' : (error.response && error.response.data);
        return errorCallback(error, message)
      }
    });
}

export {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
  BASE_URL,
}
