import { Fragment, useState, useEffect } from 'react'
import { Menu, Popover, Transition } from '@headlessui/react'
import { Helmet } from "react-helmet";
import SEO from "../../components/common/seo";
import LoaderFancy from '../../components/common/loaderfancy.jsx';
import {
  AcademicCapIcon,
  BanknotesIcon,
  // Bars3Icon,
  BellIcon,
  CheckBadgeIcon,
  ClockIcon,
  ReceiptRefundIcon,
  UsersIcon,
  // XMarkIcon,
} from '@heroicons/react/outline'
// import { MagnifyingGlassIcon } from '@heroicons/react/outline'

import SubInfo from "./subInfo";
import SubContributors from "./subContributors";
import SubReach from "./subReach";
import SubSimilar from "./subSimilar";
import SubStats from "./subStats";
import SubTopics from "./subTopics";
import SubPosts from "./subPosts";
import SubSearch from "./subSearch";
import SubChart from "./subChart";

import { redditService } from '../../services/reddit';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example({ match }) {

  const { slug } = match.params;
  const [subreddit, setSubreddit] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const isMobile = window.screen.width < 600;

  // effects
  useEffect(() => {
    fetchSubreddit();
  }, [slug]);

  // acions
  const fetchSubreddit = () => {
    setLoading(true);
    setSubreddit(null);
    redditService.fetchSubreddit(slug, (results) => {
      if (results && results.data){
        setSubreddit(results.data)
      } else {
        setError("Subreddit not found")
      }
      setLoading(false)
    }, (response, error) => {
      setLoading(false)
      // setError(error);
      if (response.response && response.response.status === 404){
        setError("Subreddit not found")
      } else if (response.response && response.response.status === 400) {
        setError(error)
      } else {
        setError("Server Error")
      }
    })
  }

  if (error){ // actual error
    return (
      <div className="text-center h-screen w-full flex flex-col items-center justify-center p-8 text-white">
        <h2 className="font-bold text-4xl opacity-25 mb-4">Uh Oh...</h2>
        <h3 className="text-2xl opacity-75">{error || 'We got an error'}</h3>
        <div className="mt-2">
          <a href="https://gummysearch.com" target="_top" className="font-semibold hover:underline">
            Go back to safety
          </a>
        </div>
      </div>
    )
  }

  if (!subreddit) return (
    <LoaderFancy showDelay={1} />
  ); // just loading

  return (
    <>
      <div className="min-h-full">
        {subreddit ? (
          <SEO title={subreddit ? `r/${subreddit.name} Summary, Stats, and Insights` : ''}
            description={`Subreddit report for r/${subreddit && subreddit.name}. ${subreddit && subreddit.description ? subreddit.description : ''}`}
            generateImage={false} path={`/subreddit/r-${subreddit.name}/`}
          />
        ) : (
          <SEO title={`r/${slug} Summary, Stats, and Insights`}
            generateImage={false} path={`/subreddit/r-${slug}/`} // minimal SEO just from the slug (need for canonical)
          />
        )}

        {subreddit ? (
          <main className="pt-8 pb-8">
            <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
              {/* Main 3 column grid */}
              <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
                {/* Left column */}
                <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                  {/* Sub Info */}
                  <SubInfo subreddit={subreddit} />

                  {/* Topics */}
                  <SubTopics subreddit={subreddit} />

                  {/* Popular Posts */}
                  <SubPosts subreddit={subreddit} />
                </div>

                {/* Right column */}
                <div className="grid grid-cols-1 gap-4">
                  <SubChart subreddit={subreddit} />

                  {/* Similar Subreddits */}
                  <SubSimilar subreddit={subreddit} />
                </div>
              </div>
            </div>
          </main>
        ) : (
          <LoaderFancy showDelay={1} />
        )}
        
        {/*<footer>
          <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="border-t border-gray-200 py-8 text-center text-sm text-gray-500 sm:text-left">
              <span className="block sm:inline">&copy; 2021 Your Company, Inc.</span>{' '}
              <span className="block sm:inline">All rights reserved.</span>
            </div>
          </div>
        </footer>*/}
      </div>
    </>
  )
}
