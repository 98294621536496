
import React from "react"
import Helmet from "react-helmet"

import { BASE_URL } from '../../utils/urls';


function SEO({ title, path, description, dontIndex, generateImage }) {

  const titleToShow = title || 'GummySearch Report'
  const metaDescription = description || `Shared GummySearch Report`;


  var url = null;
  const fullPath = path || window.location.pathname;
  if (fullPath){
    url = `${BASE_URL}${fullPath}`
  }

  // default share image
  var imageUrl = null;
  if (url && generateImage){
    imageUrl = `https://api.apiflash.com/v1/urltoimage?access_key=8691579b1c3a4e5198b4186fdd13ce98&height=675&width=1200&response_type=image&delay=2&format=png&url=` + encodeURIComponent(url);
  }

  return (
    <Helmet
      title={title}
      //titleTemplate={`%s`}
    >
      <link rel="canonical" href={url} />

      <meta name="title" content={titleToShow} />
      <meta name="description" content={metaDescription} />

      <meta property="og:title" content={titleToShow} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={metaDescription} />
      {imageUrl ? (
        <meta property="og:image" content={imageUrl} />
      ) : ''}
      
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content='@gummy_search' />
      <meta property="twitter:creator" content='@foliofed' />
      <meta property="twitter:title" content={titleToShow} />
      <meta property="twitter:description" content={metaDescription} />

      {dontIndex ? (
        <meta property="robots" content="noindex, follow" />
      ) : (
        <meta property="robots" content="index, follow" />
      )}
    </Helmet>
  )
}

export default SEO
