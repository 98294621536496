import React, { useState, useEffect } from "react"
import {
  Link
} from "react-router-dom";
import { Helmet } from "react-helmet";
import SEO from "../components/common/seo";

import { sharedService } from '../services/shared';


const Home = ({history, location, match, currentUser }) => {

  const [collections, setCollections] = useState([]);
  const [loadingCollections, setLoadingCollections] = useState(false);
  const [subreddits, setSubreddits] = useState([]);
  const [loadingSubreddits, setLoadingSubreddits] = useState(false);

  // // effects
  // useEffect(() => {
  //   getPublicCollections();
  //   getPublicSubreddits();
  // }, []);

  // const getPublicCollections = () => {
  //   setLoadingCollections(true);
  //   setCollections([]);
  //   sharedService.fetchPublicSharedCollections((results) => {
  //     setCollections(results.data)
  //     setLoadingCollections(false)
  //   }, (response, error) => {
  //     setLoadingCollections(false)
  //   })
  // }

  // const getPublicSubreddits = () => {
  //   setLoadingSubreddits(true);
  //   setSubreddits([]);
  //   sharedService.fetchPublicSharedSubreddits((results) => {
  //     setSubreddits(results.data.names)
  //     setLoadingSubreddits(false)
  //   }, (response, error) => {
  //     setLoadingSubreddits(false)
  //   })
  // }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <SEO title={'GummySearch Shared Reports'} description={"Public reports from GummySearch"} path={"/"} />
  
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-900"></div>
        </div>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                {/* Heroicon name: outline/exclamation */}
                <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h1 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                  Uh oh, you must be lost
                </h1>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Go back to <a href="https://gummysearch.com" className="underline">GummySearch</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">

            <a href="https://gummysearch.com?utm_source=share.gummysearch.com">
              <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                Go to GummySearch
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home;

