// import { useJwt, isExpired } from "react-jwt";
import { postRequest, getRequest } from '../utils/axios';
import { toQueryString } from '../utils/urls';
import axios from 'axios';


export const redditService = {
    getConversations,
    getConversationExtras,
    getConversationAggregates,

    performSubmissionSearch,
    performCommentSearch,
    fetchSubreddit,
    fetchSubreddits,
    fetchSubredditTypes,
    fetchSubredditFlair,
    fetchSubredditVolume,
    fetchSubredditKeywords,
    fetchSubredditTopics,
    fetchSubredditSubmissions,
    fetchSubredditInfluencers,
    fetchRedditor,
    fetchReplies,

    fetchTopSubreddits, // for top 40 charts

    // nlp stuff
    analyzeConversations,
    analyzeConversationsEstimate,
};

// should replace performSubmissionSearch & performCommentSearch as it can do both
function getConversations(params, onSuccess, onError) {
  return getRequest(
    `/api/v1/reddit/conversations/${toQueryString(params)}`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

// fetches non-critical additional info on conversations (redirected, updated scores, saved lists, etc)
function getConversationExtras(params, onSuccess, onError) {
  return getRequest(
    `/api/v1/reddit/conversations/extra/${toQueryString(params)}`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

// fetches aggregate info on a query
function getConversationAggregates(params, onSuccess, onError) {
  return getRequest(
    `/api/v1/reddit/conversations/aggregates/${toQueryString(params)}`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function performSubmissionSearch(params, onSuccess, onError) {
  return getRequest(
    `/api/v1/reddit/submissions/${toQueryString(params)}`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function performCommentSearch(params, onSuccess, onError) {
  return getRequest(
    `/api/v1/reddit/comments/${toQueryString(params)}`,  // TODO: check if we shouldforce backend to psaw
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function fetchSubreddit(slug, onSuccess, onError) {
  return getRequest(
    `/api/v1/reddit/subreddits/${slug}/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}


function fetchSubreddits(subreddits, onSuccess, onError) {
  return getRequest(
    `/api/v1/reddit/subreddits/?subreddits=${subreddits.join(",")}`,  // force backend to psaw
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function fetchSubredditKeywords(subreddit, onSuccess, onError) {
  return getRequest(
    `/api/v1/reddit/subreddits/${subreddit}/keywords/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function fetchSubredditTopics(subreddit, onSuccess, onError) {
  return getRequest(
    `/api/v1/reddit/subreddits/${subreddit}/topics/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function fetchSubredditInfluencers(subreddit, onSuccess, onError) {
  return getRequest(
    `/api/v1/reddit/subreddits/${subreddit}/influencers/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function fetchSubredditFlair(subreddit, onSuccess, onError) {
  return getRequest(
    `/api/v1/reddit/subreddits/${subreddit}/flair/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function fetchSubredditTypes(subreddit, onSuccess, onError) {
  return getRequest(
    `/api/v1/reddit/subreddits/${subreddit}/types/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function fetchSubredditVolume(subreddit, onSuccess, onError) {
  return getRequest(
    `/api/v1/reddit/subreddits/${subreddit}/volume/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function fetchSubredditSubmissions(subreddit, onSuccess, onError) {
  return getRequest(
    `/api/v1/reddit/subreddits/${subreddit}/popular/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function fetchTopSubreddits(params, onSuccess, onError) {
  return getRequest(
    `/api/v1/reddit/subreddits/top/${toQueryString(params)}`, 
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function fetchRedditor(username, onSuccess, onError) {
  return getRequest(
    `/api/v1/reddit/redditor/${username}/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

// params has key of either "submission" or "comment" (to implement)
function fetchReplies(params, onSuccess, onError) {
  return getRequest(
    `/api/v1/reddit/comments/replies/${toQueryString(params)}`, 
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}


function analyzeConversationsEstimate(type, ids, onSuccess, onError) {
  const paramsToUse = {
    'ids': ids,
    'type': type,
  }
  return getRequest(
    `/api/v1/reddit/conversations/analyze_estimate/${toQueryString(paramsToUse)}`, 
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function analyzeConversations(type, ids, params, onSuccess, onError) {
  const paramsToUse = Object.assign(params, {
    'ids': ids,
    'type': type,
  })
  return getRequest(
    `/api/v1/reddit/conversations/analyze/${toQueryString(paramsToUse)}`, 
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}
