import React, { useState, useEffect } from "react";
import {
  Link
} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { Popover } from 'react-tiny-popover'

import { redditService } from '../../services/reddit';
import { velocityToString, capitalizeFirst } from '../../utils/text';

import PreviewRedditSubmission from "../reddit/submissionpreview";
import SubmissionTypeIcon from "../reddit/submissiontypeicon";

// this is the "in a nutshell" view of a subreddit
// used in both internal & publically-shared product. watch out!
const SubredditTopics = ({ subreddit, hideIfBlankSection, showtopicsExamples, dontTrackRedirect }) => {
  const [loadingTopics, setLoadingTopics] = useState(true);
  const [topics, setTopics] = useState({});
  const [loadingFlair, setLoadingFlair] = useState(true);
  const [flair, setFlair] = useState([]);
  const [isHovering, setIsHovering] = useState(null); // tracks the phrase that's currently hovering

  useEffect(() => {
    fetchTopics();
    fetchFlair();
    // fetchTypes();
  }, []);


  const fetchTopics = () => {
    setLoadingTopics(true)
    redditService.fetchSubredditTopics(subreddit.name, (response) => {
      setTopics(response.data)
      setLoadingTopics(false)
    }, (error, response) => {
      console.error(response)
      setLoadingTopics(false)
    });
  }

  const fetchFlair = () => {
    setLoadingFlair(true)
    redditService.fetchSubredditFlair(subreddit.name, (response) => {
      setFlair(response.data)
      setLoadingFlair(false)
    }, (error, response) => {
      console.error(response)
      setLoadingFlair(false)
    });
  }

  // to make width
  var maxCount = 1;
  if (topics && topics && topics.length){
    // maxCount = Math.max(...[...topics.popular.map(k => k.count), ...topics.map(k => k.count)]);
    maxCount = Math.max(...topics.map(k => k.count));
  }

  if (loadingTopics || loadingFlair){
    return (
      <div className="flex items-center text-center h-full justify-center">
        <svg className="animate-spin h-6 block mx-auto w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
    )
  }

  return (
    <div id="subreddit-topics" className="text-sm pr-2">
      {topics.popular && !topics.popular.length && topics && !topics.length ? (
        <div className="opacity-25">
          None found...<br/>
          This subreddit is probably inactive
        </div>
      ) : (
        <React.Fragment>

          {topics && topics.length ? (
            <div className="" id={`subreddit-topics-${subreddit.name}`}>
              <dt className="flex items-center">
                <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
                  Popular Topics
                </h3>
                <ReactTooltip id={`topics-info`} place="right" effect="solid" backgroundColor="white" textColor="black" />
                <span className="cursor-pointer ml-2" data-for='topics-info' data-tip="Common topics discussed in the most recent submissions in the subreddit">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </span>
              </dt>
              <dd className="mt-1 flex items-center flex-wrap">
                {topics.map((k) => (
                  <div key={k.topic} className="px-2 py-1 flex space-x-1 items-center text-xs mr-1 mt-1 rounded-sm"
                    style={{
                      'background': k.color || 'rgb(55, 65, 81)',
                      'color': k.color ? 'black' : 'white',
                    }}
                  >
                    <span>{capitalizeFirst(k.topic)}</span>
                  </div>
                ))}
              </dd>
            </div>
          ) : ''}

          {(flair && flair.length) || !hideIfBlankSection ? (
            <div className="mt-4" id="subreddit-topics">
              <dt className="flex items-center">
                <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
                  Flair Used
                </h3>
                <ReactTooltip id={`flair-info`} place="right" effect="solid" backgroundColor="white" textColor="black" />
                <span className="cursor-pointer ml-2" data-for='flair-info' data-tip="Labels that users add to their posts on Reddit">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </span>
              </dt>
              {flair && flair.length ? (
                <div className="flex flex-wrap">
                  {flair.map(f => (
                    <div key={f.label} className="px-2 py-1 flex space-x-1 items-center text-xs mr-1 mt-1 rounded-sm"
                      style={{
                        'background': f.color || 'rgb(55, 65, 81)',
                        'color': f.color ? 'black' : 'white',
                      }}
                    >
                      <span className="font-semibold opacity-75">{f.percent}%</span>
                      <span>{f.label}</span>
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  This community does not use flair in their submissions
                </div>
              )}  
            </div>  
          ) : ''}      

          
        </React.Fragment>
      )}
    </div>
  );
}

export default SubredditTopics;
