const BASE_API_URL = process.env.NODE_ENV === 'production' ? `https://${process.env.REACT_APP_RENDER_API_SERVICE}.onrender.com` : 'http://localhost:8000';
const BASE_URL = process.env.NODE_ENV === 'production' ? `https://share.gummysearch.com` : 'http://localhost:8002';

// TODO: handle BASE_URL for staging environments

const toQueryString = (params) => {
	return '?' + Object.keys(params).map(function(key) {
	    return key + '=' + params[key]
	}).join('&');
}

const getParamsFromUrl = (location) => {

	// returns object of params from current url
	var search = (location || window.location).search.substring(1);
	if (search === ""){
		return {};
	}

  // uses URLSearchParams from modern browsers to get params
  var to_return = {};
  var searchParams = new URLSearchParams(search);
  for(var pair of searchParams.entries()) {
      to_return[pair[0]] = pair[1]
  }
  return to_return


  // below method worked, but would break with a "&" in the query params


  //    // decode if it's encoded!
  //    search = decodeURIComponent(search);

	// return JSON.parse('{"' + decodeURI(search)
	// 	.replace(/"/g, '\\"')
	// 	.replace(/&/g, '","')
	// 	.replace(/=/g,'":"') + '"}')
}

// TODO: could get this by using getParamsFromUrl 
const getParameterByName = (name, location) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(location ? location.href : window.location.href);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export {
    toQueryString,
    getParamsFromUrl,
    getParameterByName,
    BASE_API_URL,
    BASE_URL,
}

