import React from "react"
import { Helmet } from "react-helmet";

import { RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Legend, ResponsiveContainer } from 'recharts';

import ReactTooltip from 'react-tooltip';
import SubredditTags from "../../components/reddit/subreddittags";
// import SEO from "../../components/common/seo"; // not needed unless we move this out of the root route

import { readableNumber } from "../../utils/text";

const SubChart = ({ subreddit }) => {

  // get the comparison data between this sub and the audience
  var comparisons  = []
  if (subreddit.calculated){
    const categories = [
      {'title': 'Size', 'key': 'percentile_members'},
      {'title': 'Activity', 'key': 'percentile_per_day_submissions'},
      {'title': 'Engagement', 'key': 'percentile_per_user_comments'},
      {'title': 'Growth', 'key': 'percentile_growth_weekly'},
      {'title': 'Moderation', 'key': 'percentile_moderation'},
      {'title': 'Performance', 'key': 'percentile_reach'},
    ]
    categories.forEach(c => {
      if (subreddit.calculated[c.key] !== null){
        var comparison = {
          "title": c.title,
          "All Subreddits": 50,
          "fullMark": 100
        }
        comparison[`r/${subreddit.name}`] = subreddit.calculated[c.key]
        comparisons.push(comparison)
      }
    })
  }

  return (
    <section aria-labelledby="announcements-title">
      <div className="overflow-hidden rounded-lg bg-gray-800 text-white shadow p-4">


        <div className="flex items-center  px-1 mb-2">
          <h2 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
            Subreddit Comparison
          </h2>
          <ReactTooltip id={`audience-comparison`} place="right" effect="solid" backgroundColor="white" textColor="black" />
          <span className="cursor-pointer ml-2" data-for='audience-comparison' data-tip="How this subreddit stacks up against all others on Reddit.">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </span>
        </div>
        
        <div className="">
          <div className="h-72 text-sm mt-1 relative">
            <ResponsiveContainer width="100%" height="100%">
              <RadarChart outerRadius={80} width={'100%'} height={'100%'} data={comparisons}>
                <PolarGrid />
                <PolarAngleAxis dataKey="title" stroke="rgb(156,163,175)" />
                {/*<PolarRadiusAxis angle={30} domain={[0, 150]} />*/}
                
                <Radar name="All Subreddits" dataKey="All Subreddits" stroke="#3498db" fill="#3498db" fillOpacity={0.7} />
                <Radar name={`r/${subreddit.name}`} dataKey={`r/${subreddit.name}`} stroke="#2ecc71" fill="#2ecc71" fillOpacity={0.5} />
                <Legend />
              </RadarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SubChart;
