import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import ReactTooltip from 'react-tooltip';

import {
  TrendingUpIcon, InformationCircleIcon, QuestionMarkCircleIcon
} from '@heroicons/react/outline'

import ErrorBoundary from "../../components/common/errorboundary";
import ErrorBar from "../../components/common/errorBar";
import SEO from "../../components/common/seo";

import { readableNumber } from '../../utils/text';

import CollectionSubredditCard from "../../components/report/collectionSubredditCard";

// import { collectionsService } from '../../services/collections';


const CollectionReport = ({ report, results }) => {
  const { collection } = results;
  const [items, setItems] = useState(results.collection_items);
  const [error, setError] = useState(null);
  const [loadingItems, setLoadingItems] = useState(false);

  const sortOptions = ['Largest', 'Growth'];
  const [sortOption, setSortOption] = useState(sortOptions[0]);

  // // effects
  // useEffect(() => {
  //   // get the collection's items
  //   fetchCollectionItems();
  // }, []);

  // const fetchCollectionItems = () => {
  //   setLoadingItems(true)
  //   collectionsService.getCollectionItems(collection.hash, (results) => {
  //     if (results && results.data){
  //       setItems(results.data.filter(i => i.external_type === "RSR"))  // ensure only subreddit for now. if we add, modify  
  //       setLoadingItems(false)
  //     }
  //   }, (response, error) => {      // setLoading(false)
  //     setError(error);
  //   })
  // }

  // get the stats
  const stats = {
    'total': items.map(s => s.item.count_users).reduce((a, b) => a + b, 0),
    'online': items.map(s => s.item.count_users_active).reduce((a, b) => a + b, 0),
  }

  var sortedItems = items;
  if (sortOption === "Largest"){
    sortedItems = sortedItems.sort((a,b) => (a.item.count_users < b.item.count_users) ? 1 : ((b.item.count_users < a.item.count_users) ? -1 : 0));
  } else if (sortOption === "Growth"){
    // first sort by daily growth, then weekly
    sortedItems = sortedItems.sort((a,b) => (a.item.calculated && b.item.calculated && a.item.calculated.growth_members_daily < b.item.calculated.growth_members_daily) ? 1 : ((a.item.calculated && b.item.calculated && b.item.calculated.growth_members_daily < a.item.calculated.growth_members_daily) ? -1 : 0))
    sortedItems = sortedItems.sort((a,b) => (a.item.calculated && b.item.calculated && a.item.calculated.growth_members_weekly < b.item.calculated.growth_members_weekly) ? 1 : ((a.item.calculated && b.item.calculated && b.item.calculated.growth_members_weekly < a.item.calculated.growth_members_weekly) ? -1 : 0))
  } else if (sortOption === "None"){
    // recently added
    sortedItems = sortedItems.sort((a,b) => (a.id < b.id) ? 1 : ((b.id < a.id) ? -1 : 0));
  }

  // get the link to redirect users from
  var link = report.affiliate_link || 'https://gummysearch.com';
  if (link.includes('?')){
    link += '&utm_source=share.gummysearch.com'
  } else {
    link += '?utm_source=share.gummysearch.com'
  }

  return (
    <ErrorBoundary>
      <SEO
        title={`${results ? results.title : 'loading'} Subreddits | GummySearch`}
        description={`A collection of ${results ? results.title : ''} subreddits: ${sortedItems.map(s => `r/` + s.item.name).join(', ')}`}
        generateImage={true} path={`/report/${report.hash}/`}
      />

      <div className="mb-6 pb-5 border-b border-solid border-gray-800 flex items-center flex-wrap">
        <div>
          <div className="flex-1 min-w-0">
            <h1 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate">{collection.name}</h1>
          </div>

          <div className="mt-2 flex flex-wrap space-x-2 sm:space-x-4 text-xs sm:text-sm">
            <div className="flex items-center text-gray-300">
              <svg className="h-4 w-4 mr-2 opacity-25" enableBackground="new 0 0 24 24" height="512" fill="currentColor" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                <path d="m21.325 9.308c-.758 0-1.425.319-1.916.816-1.805-1.268-4.239-2.084-6.936-2.171l1.401-6.406 4.461 1.016c0 1.108.89 2.013 1.982 2.013 1.113 0 2.008-.929 2.008-2.038s-.889-2.038-2.007-2.038c-.779 0-1.451.477-1.786 1.129l-4.927-1.108c-.248-.067-.491.113-.557.365l-1.538 7.062c-2.676.113-5.084.928-6.895 2.197-.491-.518-1.184-.837-1.942-.837-2.812 0-3.733 3.829-1.158 5.138-.091.405-.132.837-.132 1.268 0 4.301 4.775 7.786 10.638 7.786 5.888 0 10.663-3.485 10.663-7.786 0-.431-.045-.883-.156-1.289 2.523-1.314 1.594-5.115-1.203-5.117zm-15.724 5.41c0-1.129.89-2.038 2.008-2.038 1.092 0 1.983.903 1.983 2.038 0 1.109-.89 2.013-1.983 2.013-1.113.005-2.008-.904-2.008-2.013zm10.839 4.798c-1.841 1.868-7.036 1.868-8.878 0-.203-.18-.203-.498 0-.703.177-.18.491-.18.668 0 1.406 1.463 6.07 1.488 7.537 0 .177-.18.491-.18.668 0 .207.206.207.524.005.703zm-.041-2.781c-1.092 0-1.982-.903-1.982-2.011 0-1.129.89-2.038 1.982-2.038 1.113 0 2.008.903 2.008 2.038-.005 1.103-.895 2.011-2.008 2.011z"/>
              </svg>
              {items.length} Subreddit{items.length === 1 ? '' : 's'}
            </div>
            <div className="flex items-center text-gray-300">
              <svg className="h-4 w-4 mr-2 opacity-25" xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
              {readableNumber(stats.total)} Members
            </div>
            <div className="hidden sm:flex items-center text-gray-300">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2 opacity-25" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />
              </svg>
              {readableNumber(stats.online)} Online
            </div>

            <ReactTooltip id={`audience-stats-${collection.hash}`} place="right" effect="solid" backgroundColor="white" textColor="black" />
                    
            {collection.calculated && collection.calculated.growth_members_weekly !== null ? (
              <div className={`flex items-center ${collection.calculated.growth_members_weekly ? 'text-green-400' : 'text-gray-400'}`}>
                <TrendingUpIcon className="h-4 w-4 mr-1" />
                <div>{collection.calculated.growth_members_weekly.toFixed(2)}% / week</div>
              </div>
            ) : collection.calculated && collection.calculated.growth_members_daily !== null? (
              <div className="text-gray-400 flex items-center">
                <TrendingUpIcon className="h-4 w-4 mr-1" />
                <div>{collection.calculated.growth_members_daily.toFixed(2)}% / day</div>
                <InformationCircleIcon className="h-4 w-4 ml-1 text-gray-500" 
                  data-for={`audience-stats-${collection.hash}`} data-tip="No weekly growth data yet. Showing growth over the past day."
                />
              </div>
            ) : ''}
          </div>
        </div>

        <div className="sm:ml-auto mt-4 md:mt-0">
          <div className="text-gray-400 font-semibold">Explore any audience on Reddit</div>
          <div className="sm:float-right mt-2 sm:mt-0">
            <a href={link}
              type="button" target="_blank"
              className="items-center px-4 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-500"
            >
              Try GummySearch for yourself
            </a>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <div className="flex items-center mb-2">
          <div className="text-lg font-medium flex items-center">
            <span>Communities</span>

            <p className="ml-2 text-gray-500 truncate">
              {loadingItems ? (
                <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              ) : (
                <span>{items.length}</span>
              )}
            </p>
          </div>

          <div className="ml-auto">
            {items.length >= 3 ? (
              <div className="ml-4 flex items-center">
                <div className="text-xs opacity-50">Sort by</div>
                <div className="ml-1 flex items-center">
                  {sortOptions.map(s => (
                    <div key={s} className={`px-2 py-1 ml-1 font-medium text-xs rounded-sm ${s === sortOption  ? 'bg-gray-600 text-white' : 'bg-gray-800 cursor-pointer hover:bg-gray-700'}`}
                      onClick={() => setSortOption(s)}
                    >
                      {s}
                    </div>
                  ))}
                </div>
              </div>
            ) : ''}
          </div>
        </div>
          
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {sortedItems.map(collectionItem => (
            <CollectionSubredditCard key={collectionItem.external_id}
              collectionItem={collectionItem} subreddit={collectionItem.item} />
          ))}
        </div>

        {sortedItems.length > 6 ? (
          <div className="mx-auto my-4 sm:mt-12 text-center">
            <div className="text-gray-400 font-semibold">Want to explore your own audience?</div>
            <div className="mt-2">
              <a href={link}
                type="button" target="_blank"
                className="items-center px-4 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-500"
              >
                Learn more about GummySearch
              </a>
            </div>
          </div>
        ) : ''}
        
      </div>

      {error ? (
        <ErrorBar error={error} setError={setError} />
      ) : ''}
    </ErrorBoundary>
  );
};

export default CollectionReport;

