// import { FullStoryAPI } from 'react-fullstory';

// track an event
const trackEvent = (eventName, props) => {
  // GA
  if (typeof(window.gtag) !== "undefined"){
     window.gtag('event', eventName, props);
  }

  // plausible
  if (typeof(window.plausible) !== "undefined"){
    window.plausible(eventName, {
      'props': props
      // callback: () => console.info('Sent event'),
    });
  }

  // posthog
  if (typeof(window.posthog) !== "undefined"){
    window.posthog.capture(eventName, {
      'props': props
    });
  }
}

// identify user to fullstory
const identifyUser = (userConfig) => {
  console.error("invalid identifyUser call")
  // if (typeof(FullStoryAPI) !== "undefined" && userConfig){
  //   const userId = `${userConfig.env.name}-${userConfig.identity.id}`
  //   FullStoryAPI('identify', userId, {
  //     environment: userConfig.env.name,
  //     is_staff: userConfig.identity.is_staff,
  //     is_beta: userConfig.identity.is_beta,
  //     objective: userConfig.profile.objective,
  //     subscription: userConfig.subscription && userConfig.subscription.key,
  //   });
  // }
}

const deidentifyUser = () => {
  console.error("invalid deidentifyUser call")
  // if (typeof(FullStoryAPI) !== "undefined"){
  //   try {
  //     FullStoryAPI('anonymize') // ideally would use FullStoryAPI.anonymize() but it's not in the package yet
  //   } catch (error) {
  //     console.error("Tried FS anonymize but threw an error", error);
  //   }
  // }
}

export {
    trackEvent,
    identifyUser,
    deidentifyUser,
}