import React, { useState, useEffect } from "react";
import {
  Link, useHistory
} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { Popover } from 'react-tiny-popover'

import { redditService } from '../../services/reddit';
import { velocityToString } from '../../utils/text';
import { trackEvent } from '../../utils/tracking';
import { toQueryString } from '../../utils/urls';

import PreviewRedditSubmission from "../reddit/submissionpreview";


const SubredditSubmissions = ({ subreddit, showSearch, showRedirect, trackRedirect }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [submissions, setSubmissions] = useState(null);

  useEffect(() => {
    setLoading(true)
    redditService.fetchSubredditSubmissions(subreddit.name, (response) => {
      setSubmissions(response.data.results)
      setLoading(false)
    }, (error, response) => {
      console.error(response)
      setLoading(false)
    });
  }, []);

  if (loading || !submissions){
    return (
      <div className="flex items-center text-center h-full justify-center">
        <svg className="animate-spin h-6 block mx-auto w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
    )
  }

  return (
    <div className="text-sm pr-2">
      {loading ? (
        <div className="flex items-center text-center h-full justify-center">
          <svg className="animate-spin h-6 block mx-auto w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      ) : (
        <div>

          <dt className="flex items-center">
            <h2 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
              Popular Posts in r/{subreddit.name}
            </h2>
            <ReactTooltip id={`submissions-info`} place="right" effect="solid" backgroundColor="white" textColor="black" />
            <span className="cursor-pointer ml-2" data-for='submissions-info' data-tip="Popular conversations in this community from the past month">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </span>
          </dt>
          <ul className="divide-y divide-gray-800">
            {submissions.map(item => (
              <React.Fragment key={item.id}>
                <PreviewRedditSubmission item={item} showMetadata={true} showRedirect={showRedirect} trackRedirect={false} />
              </React.Fragment>
            ))}
          </ul>

          {submissions.length === 0 ? (
            <div className="opacity-25">
              None found...<br/>
              This subreddit is probably inactive
            </div>
          ) : ''}
        </div>
      )}
    </div>
  );
}

export default SubredditSubmissions;
